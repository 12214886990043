.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.app-main {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
}

.app-header {
  background-color: #0a1a2d;
  color: #fff;
  width: 100%;
  margin-bottom: 20px;
  z-index: 20;
  opacity: 70%;
}

.header {
  float: right;
}

.headerButton {
  float: right;
}

/***************************************************************************/
@media (min-width: 800px) {
  .leftMenu {
    margin-top: 15px;
    margin-left: 15px;
    flex-direction: column;
    background-color: #482756;
    opacity: 75%;
    z-index: 10;
    border-radius: 15px;
    justify-content: left;
    padding: 5px;
    align-self: flex-start;
  }

  .leftMenu .leftMenuItemDiv {
    width: 159px;
    height: 42px;
    margin: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px;
    padding-left: 8px;
    background: linear-gradient(90deg, #2945a8 0%, #b732e3 100%);
    border-radius: 17px;
  }

  .leftMenu .leftMenuItemDiv:hover {
    background-color: #9b36de;
    background: linear-gradient(90deg, #9b36de 0%, #b732e3 100%);
  }

  .leftMenu a {
    background-color: #4442b0;
    color: #fff;
    opacity: 200% !important;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
    width: 180px;
    transition: background-color 0.3s ease;
  }

  .leftMenu a:hover {
    background-color: #9b36de;
  }

  .leftMenu text {
    font-size: larger;
    margin-left: 7px;
    margin-top: 7px;
    vertical-align: text-bottom;
    float: left;
  }

  .leftMenuItemImage {
    width: 32px;
    height: 32px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    float: left;
  }
}

/********************************************************************************/
@media (max-width: 800px) {
  .leftMenu {
    width: 100%;
    background-color: none;
    display: flex;
    justify-content: center;
    position: absolute !important;
    top: 5px;
    left: 0;
    z-index: 40;
  }

  .leftMenu .leftMenuItemDiv {
    width: 49px;
    height: 49px;
  }

  .leftMenu button {
    /* background-color: #4442b0; */
    color: #fff;
    border: none;
    /* border-radius: 10px; */
    cursor: pointer;
    margin: 0px;
    width: 100%;
    height: 100%;
  }

  .leftMenu button:hover {
    background-color: #9b36de;
  }

  .leftMenu text {
    visibility: hidden;
  }

  .leftMenuItemImage {
    width: 42px;
    height: 42px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  /* .leftMenuItemImage button:hover {
    background-color: #9b36de;
  } */
}

/********************************************************************************/

.restaurantCard {
  z-index: 10;
}

.flex-container {
  display: flex;
  justify-content: center;
  position: absolute !important;
  bottom: 0;
  left: 0;
}

@media (min-width: 800px) {
  .menuFlexContainer {
    position: absolute;
    top: 10px;
  }

  .restContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    /* width: 75%; */
    width: 40%;
    max-width: fit-content;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 25px;
    border-radius: 10px;
    /* background: #482756; */
    /* background: linear-gradient(#14226b 0%, #5b294e 100%); */
    background: linear-gradient(#3b0f6a 0%, #bd322d 100%);
    color: white;

    z-index: 30;
  }
}

@media (max-width: 800px) {
  .menuFlexContainer {
    position: absolute;
    top: 0px;
  }

  .restContainer {
    display: flex;
    flex-direction: column;
    /* width: 75%; */
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 10px;
    /* background: #482756; */
    background: linear-gradient(#3b0f6a 0%, #bd322d 100%);
    color: white;

    z-index: 30;
  }
}

.restPhoto {
  width: 100%;
  border-radius: 10px;
}

.restCard {
  background-color: white;
  border-radius: 5px;
  text-align: left;
  padding: 5px;
}

.restCard h3 {
  color: #0a1a2d;
  font-size: medium;
  font-weight: bolder;
  margin-top: 3px;
  margin-bottom: 3px;
}

.restCard text {
  color: rgba(0, 0, 0, 0.438);
  font-size: medium;
}

.restContainer button {
  background-image: linear-gradient(90deg, #2945a8 0%, #b732e3 100%);

  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 110px;
  height: 35px;
  align-self: center;
}

.restContainer button:hover {
  background-image: linear-gradient(90deg, #b732e3 0%, #b732e3 100%);
}

.restContainer button.active {
  background-image: linear-gradient(90deg, #b732e3 0%, #b732e3 100%);
}

/***************************************************************************************/
@media (min-width: 800px) {
  .modalContainer {
    position: absolute;
    top: 15px;
    display: flex;
    flex-direction: column;
    /* width: 75%; */
    width: 40%;
    max-width: fit-content;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 25px;
    border-radius: 10px;
    /* background: #482756; */
    /* background: linear-gradient(#14226b 0%, #5b294e 100%); */
    background: linear-gradient(#3b0f6a 0%, #bd322d 100%);
    color: white;

    z-index: 30;
  }
}

@media (max-width: 800px) {
  .modalContainer {
    position: relative;
    top: 75px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 10px;
    padding: 15px;
    background: linear-gradient(#3b0f6a 0%, #bd322d 100%);
    color: white;

    z-index: 30;
  }
}

.modalContainer button {
  background-image: linear-gradient(90deg, #2945a8 0%, #b732e3 100%);

  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 110px;
  height: 35px;
  align-self: center;
}

.modalContainer button:hover {
  background-image: linear-gradient(90deg, #b732e3 0%, #b732e3 100%);
}

.modalContainer button.active {
  background-image: linear-gradient(90deg, #b732e3 0%, #b732e3 100%);
}

/***************************************************************************************/
.backButton {
  position: absolute;
  top: 10px;
  margin-left: 15px;
  z-index: 50;
}

.backButton button {
  height: 35px;
  width: 35px;
  font-size: x-large;
}

.favoriteButtonContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  background-image: none !important;
}

.favoriteButtonContainer button {
  height: 35px;
  width: 35px;
  font-size: x-large;
}

/******************************************************************************************/

.menuItemLink {
  color: white;
  font-weight: bolder;
}

.menuCategories {
  text-align: left;
  padding-left: 5px;
}

.menuCategories button {
  margin-right: 7px;
}

.smallMenuItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.menuItemSmall {
  position: relative;
  background-color: white;
  width: 150px;
  height: 200px;
  border-radius: 7px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
}

.menuItemSmall img {
  background-color: rgba(255, 255, 255, 0.852);
  width: 95%;
  border-radius: 7px;
  margin: 3px;
}

.menuItemSmall a {
  text-decoration: none;
  color: black;
}

.menuItemSmall .itemPriceContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 7px;
}

.menuItemSmall .itemPrice {
  background-color: darkgray;
  border-radius: 9px;
  width: 80px;
}

/*******************************************************************************/
.itemSelectedContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

@media (min-width: 800px) {
  .itemImageSelected {
    margin: auto;
    margin-top: 10px;
    width: 370px;
    padding: 4px;
    background-color: white;
    border-radius: 5px;
  }

  .itemImageSelected img {
    width: 370px;
    border-radius: 5px;
  }
}

@media (max-width: 800px) {
  .itemImageSelected {
    margin: auto;
    margin-top: 10px;
    width: 75%;
    padding: 4px;
    background-color: white;
    border-radius: 5px;
  }

  .itemImageSelected img {
    width: 75%;
    border-radius: 5px;
  }
}

.itemDetailsSelected {
  display: flex;
  flex-direction: column;
  margin: auto;

  margin-bottom: 5px;
}

.itemDetailsSelected .nameAndPrice {
  font-weight: bolder;
  margin-top: 5px;
  margin-bottom: 5px;
}

.itemSelectedForm {
  margin: auto;
  margin-bottom: 5px;
}

.itemSelectedForm #amount {
  width: 2rem;
}

/************************************************************/
.paymentForm {
  width: 87%;
  text-align: left;
  margin-bottom: 10px;
  padding: 10px;
}

.paymentForm label {
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.paymentForm input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.paymentForm .expCvv {
  display: flex;
}

.paymentForm .expCvv input {
  width: 40%;
  margin-left: 5px;
}

.paymentForm .paymentButtons {
  text-align: center;
}

.paymentForm .paymentButtons button {
  margin: auto;
  margin-right: 5px;
  margin-left: 5px;
}

/*******************************************************************/
.cheerUpText {
  margin-top: 10px;
  font-size: larger;
  font-weight: bolder;
}

.barcode {
  margin-top: 10px;
  margin-bottom: 10px;
}

.barcode img {
  width: 200px;
  background-color: white;
  border-radius: 5px;
}

.giftItem {
  display: flex;
  width: 320px;
  margin-left: 10px;
  margin-right: 10px;
  align-self: center;

  flex-direction: row;
}

.giftItem img {
  width: 80px;
  border-radius: 5px;
}

.giftItemText {
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
}

.giftItemText h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.giftItemText h4 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.giftItem button {
  background-image: none;
  /* background-color: #4442b0; */
  background-color: none;
  color: black;
  /* opacity: 15% !important; */
}

.sendGiftButton {
  width: 42px;
  height: 38px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 15px;
  border-color: white;
  border-radius: 9px;
  border-style: solid;
  font-size: x-large;
  padding-top: 7px;
  text-align: center;
  cursor: pointer;
}

.sendGiftButton:hover {
  background-color: #9b36de;
}

.barcode-row {
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.barcode-row div {
  align-self: center;
  margin: auto;
  width: 256px;
  border-radius: 7px;
  padding: 17px;
  background-color: white;
}

/******************************************************************************************/
.cartItem {
  display: flex;
  width: 320px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 7px;
  background-color: #9b36de;
  border-radius: 7px;
  padding: 7px;
  align-self: center;
  flex-direction: row;
}

.cartItem img {
  width: 80px;
  border-radius: 5px;
}

.cartItemText {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}

.cartItemTextText {
  width: 90%;
}

.deleteItemDiv {
  /* background-color: #4442b0; */
  /* opacity: 15% !important; */
}

.cartItemText h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.cartItemText h4 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.cartItemText edit {
  margin-top: 0px;
  margin-bottom: 0px;
}

.cartItem input {
  width: 35px;
  /* background-color: #4442b0; */
  /* opacity: 15% !important; */
}

.deleteItem {
  cursor: pointer;
  vertical-align: middle;
}

.deleteItem:hover {
  color: #de363e;
}
